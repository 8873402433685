<template>
 <div style="padding:10px;min-height:100vh;background:#f3f3f3">
    <div class="header">
      <van-row gutter="10" @click="onClickBack">
        <van-col span="12" style="padding-top:.1rem">
          <svg-icon
            :data_iconName="'back-active'"
            :className="'back_icon'"
            style="height: 25px;color:inherit"
          />
          <span style="font-size: 25px; color: #333;font-weight:bold;margin-left:.1rem">个人中心</span>
        </van-col>
        <!-- <van-col span="12" class="header_action_title"
          @click="submit">完成</van-col> -->
      </van-row>
    </div>
    <card style="margin-top: 30px;margin-bottom:70px;height:60vh;overflow-y:scroll">
      <template #content>
        <fieldForm
          :submit="submit"
          :modal="user"
          :fieldData="fields"
          v-on:formUpdate="onFormUpdate"
        ></fieldForm>
        <van-cell border clickable is-link title="修改密码" to="/wode/changePassword"/>
      </template>
    </card>
  </div>
</template>

<script>
import api from "@/services/apis.js";
import fieldForm from "@/views/components/FieldForm";
import card from "@/views/components/Card"
import {mapGetters} from "vuex"
import { Toast } from 'vant'
import {getFormatDate} from "@/utils/common.utils"
export default {
  components: {
    fieldForm,
    card
  },
  data() {
    return {
      showVirthDateSelect: false,
      fields: [
        {
          title: "头像",
          name: "avatar",
          type: "file",
          rule: [],
          max_count: 1,
          inline: false,
        },
        {
          title: "姓名",
          name: "username",
          type: "text",
          disabled: false,
          rule: [{ required: true, message: "" }],
          inline: false,
        },
        {
          title: "性别",
          name: "gender",
          type: "select",
          values: ['男', '女'],
          rule: [{ required: true, message: "" }],
          inline: false
        },
        {
          title: "身份证号",
          name: "id_card",
          type: "text",
          disabled: false,
          rule: [
            { required: true, message: "" },
            {
              validator: this.idCardValidate,
              message: "身份证格式不正确"
            }
          ],
          inline: false,
        },
        {
          title: "出生年月",
          name: "birth_date",
          type: "date_select",
          rule: [{ required: true, message: "" }],
          inline: false
        },
        {
          title: "电话",
          name: "mobile",
          type: "text",
          disabled: true,
          inline: false,
          rule: [{ required: true, message: "" }],
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch('wode/getPersonInfo')

  },
  computed: {
    ...mapGetters('wode', {
      user: 'getFmtPersonInfo',
      personInfo: 'getPersonInfo'
    }),
  },
  methods: {
    idCardValidate(val) {
      const reg = /(^\d{15}$)|(^\d{17}([0-9]|X)$)/
      return reg.test(val)
    },
    submit() {
      let self = this
      api.visitor_update(self.user).then(function(res) {
        if(res.status === 200) {
          self.$router.push('/home')
        } else {
          Toast(res.message)
        }
      })
    },
    onClickBack() {
      this.$router.go(-1)
    },
    onFormUpdate(data) {
      const personInfo = this.personInfo
      personInfo.user.username = data.username
      personInfo.tourist.gender = data.gender
      personInfo.tourist.id_card = data.id_card
      personInfo.user.avatar = data.avatar
      personInfo.tourist.birth_date = getFormatDate(data.birth_date)
      
      this.$store.dispatch('wode/syncPersonInfo', personInfo)
    }
  },
};
</script>

<style scoped>
.action_btn {
  position: fixed;
  bottom: 10px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  height: 50px;
  left: 2.5%;
  text-align: center;
}
.action_bg {
  padding-top: 10px;
  position: fixed;
  bottom: 0px;
  height: 70px;
  width: 100%;
  left: 0px;
  text-align: center;
  background-color: white;
  z-index: 666;
}
.header_action_title {
  font-size: .5rem; color: #333;font-weight:bold;
  text-align:right;line-height:.5rem;padding-top: .2rem;
}
</style>